$(function() {
  $('a[href^="#"]').on('click', function(e){
    e.preventDefault();
    var href = $(this).attr('href');
    $(href).attr('tabindex', -1);
    $(href).focus();
    $(href).on('blur', function(e){
      $(this).removeAttr('tabindex');
    });
  });
});
