$(function() {
  // print selected file by file input
  $('.form__file input[type="file"]').change(function(e){
    var fileName = e.target.files[0].name;
    var $formFile = $(this).closest('.form__file');
    $formFile.find('.form__file-names').removeClass('form__file-names--empty').html(''); // Clear the previous value.
    $formFile.find('.form__file-names').append('<div class="form__file-name">' + fileName + '</div>');
  });

  $( document ).ready(function() {
    if ($('.alerts-banner--important').length) {
      $('.alerts-banner--important').focus();
    }
  });

  $('.form__password-show-toggle').on('click', function(e){
    e.preventDefault();
    var $input = $(this).closest('.form__group').find('input');
    if ($input.attr('type') == 'password') {
      $input.attr('type', 'text');
      $(this).addClass('form__password-show-toggle--active');
    }
    else {
      $input.attr('type', 'password');
      $(this).removeClass('form__password-show-toggle--active');
    }
  });
});